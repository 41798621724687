import React, { useState } from 'react';
import { Menu, X, Plus, Bell, User, HelpCircle } from 'lucide-react';
import DashboardView from './components/views/DashboardView';
import DeviceTestingView from './components/views/DeviceTestingView';
import TestProgressView from './components/views/TestProgressView';

const EnhancedMobileTestingPlatform = () => {
  const [currentView, setCurrentView] = useState('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAIChatOpen, setIsAIChatOpen] = useState(false);

  const renderView = () => {
    switch(currentView) {
      case 'testing':
        return <DeviceTestingView />;
      case 'progress':
        return <TestProgressView />;
      default:
        return <DashboardView />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navigation Bar */}
      <nav className="bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img className="h-8 w-auto" src="/api/placeholder/32/32" alt="Logo" />
              <div className="hidden md:block ml-10 flex items-baseline space-x-4">
                <button 
                  onClick={() => setCurrentView('dashboard')}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    currentView === 'dashboard' 
                      ? 'bg-gray-900 text-white' 
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  }`}
                >
                  Dashboard
                </button>
                <button 
                  onClick={() => setCurrentView('testing')}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    currentView === 'testing' 
                      ? 'bg-gray-900 text-white' 
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  }`}
                >
                  Device Testing
                </button>
                <button 
                  onClick={() => setCurrentView('progress')}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    currentView === 'progress' 
                      ? 'bg-gray-900 text-white' 
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  }`}
                >
                  Test Progress
                </button>
              </div>
            </div>
            
            {/* Existing header buttons */}
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <button className="bg-indigo-500 p-1 rounded-full text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <Plus className="h-6 w-6" />
                </button>
              </div>
              <button className="ml-3 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <Bell className="h-6 w-6" />
              </button>
              <button className="ml-3 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <User className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main>
        {renderView()}
      </main>

      {/* AI Chat Interface */}
      <div className="fixed bottom-4 right-4">
        <button
          onClick={() => setIsAIChatOpen(!isAIChatOpen)}
          className="bg-indigo-600 text-white rounded-full p-3 shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <HelpCircle className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default EnhancedMobileTestingPlatform;