import React, { useState } from 'react';
import { X, Settings, AlertCircle } from 'lucide-react';
import { useTestingPlatform } from '../../services/api-service';

const TestConfigModal = ({ isOpen, onClose, testConfig, setTestConfig }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [testRuns, setTestRuns] = useState([]);
  const { service } = useTestingPlatform();

  if (!isOpen) return null;


  const fetchTestRuns = async () => {
    setIsLoading(true);
    try {
      // Only fetch if we have an active test
      const tests = await service.getTestStatus();
      if (tests) {
        setTestRuns(tests);
      }
    } catch (error) {
      // Log error but don't show to user unless necessary
      console.error('Failed to fetch test runs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const defaultConfigs = {
    android: {
      automationName: 'UiAutomator2',
      platformName: 'Android',
      noReset: false,
      fullReset: true,
      autoGrantPermissions: true,
      newCommandTimeout: 300,
    },
    ios: {
      automationName: 'XCUITest',
      platformName: 'iOS',
      noReset: false,
      fullReset: true,
      autoAcceptAlerts: true,
      newCommandTimeout: 300,
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-[800px] max-h-[90vh] overflow-hidden">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-medium">Test Configuration</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-120px)]">
          {/* Basic Configuration */}
          <div className="mb-8">
            <h3 className="text-md font-medium mb-4">Basic Configuration</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Test Name
                </label>
                <input
                  type="text"
                  value={testConfig.name}
                  onChange={(e) => setTestConfig(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Enter test name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Test Type
                </label>
                <select
                  value={testConfig.testType}
                  onChange={(e) => setTestConfig(prev => ({ ...prev, testType: e.target.value }))}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="functional">Functional Testing</option>
                  <option value="performance">Performance Testing</option>
                  <option value="compatibility">Compatibility Testing</option>
                  <option value="regression">Regression Testing</option>
                  <option value="smoke">Smoke Testing</option>
                </select>
              </div>
            </div>
          </div>

          {/* Test Settings */}
          <div className="mb-8">
            <h3 className="text-md font-medium mb-4">Test Settings</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Timeout (minutes)
                </label>
                <input
                  type="number"
                  value={testConfig.timeout}
                  onChange={(e) => setTestConfig(prev => ({ ...prev, timeout: parseInt(e.target.value) }))}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  min="1"
                  max="120"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Retry Count
                </label>
                <input
                  type="number"
                  value={testConfig.retryCount}
                  onChange={(e) => setTestConfig(prev => ({ ...prev, retryCount: parseInt(e.target.value) }))}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  min="0"
                  max="5"
                />
              </div>
            </div>
          </div>

          {/* Test Options */}
          <div className="mb-8">
            <h3 className="text-md font-medium mb-4">Test Options</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-3">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={testConfig.recordVideo}
                    onChange={(e) => setTestConfig(prev => ({ ...prev, recordVideo: e.target.checked }))}
                    className="rounded text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-700">Record Video</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={testConfig.captureScreenshots}
                    onChange={(e) => setTestConfig(prev => ({ ...prev, captureScreenshots: e.target.checked }))}
                    className="rounded text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-700">Capture Screenshots</span>
                </label>
              </div>
              <div className="space-y-3">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={testConfig.enableDebugMode}
                    onChange={(e) => setTestConfig(prev => ({ ...prev, enableDebugMode: e.target.checked }))}
                    className="rounded text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-700">Enable Debug Mode</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={testConfig.enableNetworkLogs}
                    onChange={(e) => setTestConfig(prev => ({ ...prev, enableNetworkLogs: e.target.checked }))}
                    className="rounded text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-700">Enable Network Logs</span>
                </label>
              </div>
            </div>
          </div>

          {/* Advanced Configuration */}
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium">Advanced Configuration</h3>
              <button
                onClick={() => setTestConfig(prev => ({ 
                  ...prev, 
                  defaultConfig: defaultConfigs[prev.platform || 'android']
                }))}
                className="text-sm text-indigo-600 hover:text-indigo-800"
              >
                Reset to Default
              </button>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Automation Name
                  </label>
                  <select
                    value={testConfig.defaultConfig?.automationName}
                    onChange={(e) => setTestConfig(prev => ({
                      ...prev,
                      defaultConfig: { ...prev.defaultConfig, automationName: e.target.value }
                    }))}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="UiAutomator2">UiAutomator2 (Android)</option>
                    <option value="XCUITest">XCUITest (iOS)</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    New Command Timeout (seconds)
                  </label>
                  <input
                    type="number"
                    value={testConfig.defaultConfig?.newCommandTimeout}
                    onChange={(e) => setTestConfig(prev => ({
                      ...prev,
                      defaultConfig: { ...prev.defaultConfig, newCommandTimeout: parseInt(e.target.value) }
                    }))}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 border-t flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              // Save configuration
              onClose();
            }}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestConfigModal;