// api-service.js
import { useState, useEffect, useRef } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8004';
const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL || 'ws://localhost:8004';

class TestingPlatformService {
  constructor() {
    this.ws = null;
    this.wsSubscribers = new Map();
    this.isConnecting = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectTimeout = null;
    this.setupWebSocket();
  }

  setupWebSocket() {
    if (this.isConnecting || (this.ws && this.ws.readyState === WebSocket.OPEN)) {
      console.log('WebSocket is already connected or connecting.');
      return;
    }

    this.isConnecting = true;
    console.log('Attempting to connect to WebSocket...');

    this.ws = new WebSocket(`${WS_BASE_URL}/ws?token=your_auth_token_here`); // Include token if needed

    this.ws.onopen = () => {
      console.log('WebSocket connected');
      this.isConnecting = false;
      this.reconnectAttempts = 0;
      this.broadcastToSubscribers('device-status', {
        type: 'connection',
        connected: true,
        timestamp: new Date().toISOString()
      });
    };

    this.ws.onmessage = (event) => {
      // Log all incoming messages for debugging
      console.log('WebSocket message received:', event.data);

      try {
        const data = JSON.parse(event.data);

        switch (data.type) {
          case 'device-status':
            if (data.data && Array.isArray(data.data.devices)) {
              data.data.devices.forEach(deviceData => {
                const deviceUpdate = {
                  device_id: deviceData.device_id,
                  status: deviceData.status,
                  connected: deviceData.connected,
                  metadata: deviceData.metadata,
                  timestamp: data.timestamp
                };
                this.broadcastToSubscribers('device-status', deviceUpdate);
              });
            }
            break;
          case 'device-update':
            this.broadcastToSubscribers('device-update', data.data);
            break;
          case 'test-status':
            this.broadcastToSubscribers('test-status', data.data);
            break;
          case 'ping':
            // Optionally handle server-initiated pings if necessary
            // The backend might handle pong responses automatically
            break;
          case 'pong':
            // Optionally handle server-initiated pongs if necessary
            break;
          default:
            console.warn('Unknown message type:', data.type);
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    };

    this.ws.onclose = (event) => {
      console.log(`WebSocket disconnected: ${event.reason || 'No reason provided'}`);
      this.isConnecting = false;

      this.broadcastToSubscribers('device-status', {
        type: 'connection',
        connected: false,
        timestamp: new Date().toISOString()
      });

      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        const backoffTime = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);
        console.log(`Reconnecting in ${backoffTime / 1000} seconds...`);
        this.reconnectAttempts++;
        this.reconnectTimeout = setTimeout(() => this.setupWebSocket(), backoffTime);
      } else {
        console.error('Max reconnect attempts reached. Giving up.');
      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket encountered an error:', error);
      // Close the WebSocket to trigger onclose and reconnection
      if (this.ws) {
        this.ws.close();
      }
    };
  }

  cleanup() {
    console.log('Cleaning up WebSocket connection...');
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }

    if (this.ws) {
      this.ws.onopen = null;
      this.ws.onmessage = null;
      this.ws.onclose = null;
      this.ws.onerror = null;
      this.ws.close();
      this.ws = null;
    }

    this.isConnecting = false;
    this.reconnectAttempts = 0;
  }

  updateFilters(filters) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({
        type: 'update_filters',
        filters: filters
      }));
    }
  }

  broadcastToSubscribers(type, data) {
    try {
      const subscribers = this.wsSubscribers.get(type) || [];
      subscribers.forEach(callback => {
        try {
          callback(data);
        } catch (error) {
          console.error(`Error in subscriber callback for ${type}:`, error);
        }
      });
    } catch (error) {
      console.error('Error broadcasting to subscribers:', error);
    }
  }

  subscribeToEvents(eventType, callback) {
    if (!this.wsSubscribers.has(eventType)) {
      this.wsSubscribers.set(eventType, []);
    }

    const subscribers = this.wsSubscribers.get(eventType);
    subscribers.push(callback);

    return () => {
      const updatedSubscribers = subscribers.filter(cb => cb !== callback);
      this.wsSubscribers.set(eventType, updatedSubscribers);
    };
  }

  async getDeviceStatus() {
    try {
      const response = await fetch(`${API_BASE_URL}/api/devices/status`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch device status:', error);
      throw error;
    }
  }

  async getTestStatus(testId) {
    try {
      const endpoint = testId 
        ? `${API_BASE_URL}/api/tests/${testId}/status`
        : `${API_BASE_URL}/api/tests/status`;
        
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch test status:', error);
      return null;
    }
  }

  async executeTest(testRequest) {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tests/execute`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(testRequest),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Failed to execute test:', error);
      throw error;
    }
  }

  async uploadApplication(file, metadata) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('metadata', JSON.stringify(metadata));

      const response = await fetch(`${API_BASE_URL}/api/apps/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Failed to upload application:', error);
      throw error;
    }
  }

  async stopTest(testId) {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tests/${testId}/stop`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Failed to stop test:', error);
      throw error;
    }
  }
}

export function useTestingPlatform() {
  const [service] = useState(() => new TestingPlatformService());
  
  const useDeviceUpdates = (callback) => {
    useEffect(() => {
      return service.subscribeToEvents('device-status', callback);
    }, [callback]);
  };

  const useTestUpdates = (callback) => {
    useEffect(() => {
      return service.subscribeToEvents('test-status', callback);
    }, [callback]);
  };

  const useDeviceEvents = (callback) => {
    useEffect(() => {
      return service.subscribeToEvents('device-update', callback);
    }, [callback]);
  };

  useEffect(() => {
    return () => {
      service.cleanup();
    };
  }, [service]);

  return {
    service,
    useDeviceUpdates,
    useTestUpdates,
    useDeviceEvents,
  };
}

export default TestingPlatformService;
