// DeviceTestingView.js
import React, { useState, useRef, useEffect } from 'react';
import { 
  Wifi,
  Camera,
  Package,
  Maximize2,
  Minimize2,
  RotateCw,
  RefreshCw,
  AlertCircle,
  Play,
  Square,
  Home,
  ArrowLeft,
  Menu as MenuIcon
} from 'lucide-react';
import { useTestingPlatform } from '../../services/api-service';
import TestConfigModal from './TestConfigModal';

const DeviceTestingView = () => {
  // State management
  
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [streamOrientation, setStreamOrientation] = useState('portrait');
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  // const [isConnected, setIsConnected] = useState(false);
  const [appFile, setAppFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({ status: 'idle', progress: 0 });
  const [filters, setFilters] = useState({
    os: 'all',
    status: 'all',
    search: ''
  });
  const [connectionStatus, setConnectionStatus] = useState({
    isConnected: false,
    lastUpdated: null
  });
  

  // Refs
  const fileInputRef = useRef(null);
  const streamContainerRef = useRef(null);
  // Add a ref to track initial load
  const initialLoadComplete = useRef(false);

  //const wsRef = useRef(null);
  const { service, useDeviceUpdates } = useTestingPlatform();

  // Test configuration state
  const [testConfig, setTestConfig] = useState({
    name: '',
    testType: 'functional',
    timeout: 30,
    recordVideo: true,
    captureScreenshots: true,
    enableDebugMode: false,
    retryCount: 1
  });

   // Add real-time device updates handler
   useDeviceUpdates((data) => {
    // Add debug logging
    console.log('WebSocket update received:', data);
  
    if (data.type === 'device-status' && data.data?.devices) {
      console.log('Processing device status update:', data.data.devices);
      
      setDevices(currentDevices => {
        const updatedDevices = currentDevices.map(device => {
          // Debug log to track device matching
          console.log('Checking device:', device.deviceId);
          
          const updatedDevice = data.data.devices.find(d => d.device_id === device.deviceId);
          if (updatedDevice) {
            console.log('Found matching device update:', updatedDevice);
            
            return {
              ...device,
              status: updatedDevice.status,  // Remove toLowerCase() to match exact status
              connected: updatedDevice.connected,
              lastSeen: updatedDevice.last_seen || updatedDevice.metadata?.last_seen,
              lastStatusChange: updatedDevice.last_status_change,
              // Keep other metadata updates
              metadata: updatedDevice.metadata
            };
          }
          return device;
        });
  
        console.log('Updated devices:', updatedDevices);
        return updatedDevices;
      });
    }
  });

  

  // Add helper function to transform device data
  const transformDeviceData = (data) => ({
    id: data.device_id,
    deviceId: data.device_id,
    name: data.metadata?.name || '',
    os: data.metadata?.os || '',
    version: data.metadata?.os_version || '',
    status: data.status?.toLowerCase() || '',
    connected: data.connected,
    manufacturer: data.metadata?.manufacturer || '',
    model: data.metadata?.hardware_model || '',
    type: data.metadata?.device_type || '',
    sdkVersion: data.metadata?.api_level || ''
  });
  

  const fetchDevices = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('http://localhost:8004/devices?skip=0&limit=100');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      // Add debug logging
      console.log('Fetched devices:', data);
      
      const formattedDevices = data.devices.map(device => ({
        id: device.id,
        deviceId: device.device_id,  // Ensure this matches the WebSocket device_id
        name: device.name,
        manufacturer: device.manufacturer,
        deviceType: device.device_type,
        model: device.hardware_model,
        os: device.os,
        version: device.os_version,
        status: device.status,
        connected: device.connected,
        lastSeen: device.last_seen,
        apiLevel: device.capabilities?.api_level,
        installedApps: device.installed_apps || [],
        type: device.device_type,
        platform: device.platform
      }));
  
      console.log('Formatted devices:', formattedDevices);
      setDevices(formattedDevices);
    } catch (error) {
      console.error('Failed to fetch devices:', error);
      setError('Failed to fetch devices. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Add drag and drop handlers
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    
    const files = event.dataTransfer?.files;
    if (files?.length > 0) {
      const file = files[0];
      const validTypes = ['.apk', '.ipa', 'application/vnd.android.package-archive', 'application/x-ios-app'];
      const fileExtension = file.name.toLowerCase().slice(file.name.lastIndexOf('.'));
      
      if (validTypes.includes(fileExtension) || validTypes.includes(file.type)) {
        fileInputRef.current.files = files;
        handleFileUpload({ target: fileInputRef.current });
      } else {
        setUploadStatus({ 
          status: 'error', 
          progress: 0,
          message: 'Invalid file type. Please upload an APK or IPA file.' 
        });
      }
    }
  };

  // File upload handling
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploadStatus({ status: 'uploading', progress: 0 });

      const maxSize = 500 * 1024 * 1024; // 500MB
      if (file.size > maxSize) {
        throw new Error('File size exceeds 500MB limit');
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('metadata', JSON.stringify({
        filename: file.name,
        type: file.type,
        size: file.size
      }));

      const response = await fetch('http://localhost:8004/apps/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();
      
      setUploadStatus({ status: 'success', progress: 100 });
      setAppFile({
        ...file,
        url: result.app_url
      });

    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus({
        status: 'error',
        progress: 0,
        message: error.message || 'Upload failed. Please try again.'
      });
    }
  };

  // Start test execution
  const startTest = async () => {
    if (!selectedDevice || !appFile?.url) {
      setError('Please select a device and upload an application first.');
      return;
    }

    try {
      const testRequest = {
        app_url: appFile.url,
        capabilities: {
          deviceName: selectedDevice.name,
          platformName: selectedDevice.os,
          platformVersion: selectedDevice.version,
          ...testConfig.defaultConfig
        },
        test_steps: [], // Configure based on your test requirements
        timeout_minutes: testConfig.timeout
      };

      const response = await fetch('http://localhost:8004/tests/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(testRequest)
      });

      if (!response.ok) {
        throw new Error('Failed to start test');
      }

      const result = await response.json();
      addLog({
        message: `Test started successfully. Test ID: ${result.test_id}`,
        level: 'info'
      });

    } catch (error) {
      console.error('Failed to start test:', error);
      setError('Failed to start test. Please try again.');
    }
  };

  // Utility functions
  const addLog = (log) => {
    setLogs(current => [...current, {
      timestamp: new Date(),
      ...log
    }]);
  };

  const handleStreamClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  const filteredDevices = (devices || []).filter(device => {
    if (!device) return false;

    const deviceOS = device.os?.toLowerCase() || '';
    const deviceStatus = device.status?.toLowerCase() || '';
    const deviceName = device.name?.toLowerCase() || '';
    const deviceModel = device.model?.toLowerCase() || '';
    const searchTerm = filters.search.toLowerCase();

    const matchesOS = filters.os === 'all' || deviceOS === filters.os.toLowerCase();
    const matchesStatus = filters.status === 'all' || deviceStatus === filters.status.toLowerCase();
    const matchesSearch = 
      deviceName.includes(searchTerm) || 
      deviceModel.includes(searchTerm) ||
      deviceOS.includes(searchTerm);

    return matchesOS && matchesStatus && matchesSearch;
  });

  const getStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
      case 'available':
        return 'bg-green-100 text-green-800';
      case 'in_use':
        return 'bg-yellow-100 text-yellow-800';
      case 'offline':
        return 'bg-gray-100 text-gray-800';
      case 'error':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  

  // Function to format the device details for display
  const getDeviceDisplayInfo = (device) => ({
    title: device.name || device.model,
    subtitle: `${device.manufacturer || ''} ${device.model || ''}`.trim(),
    details: [
      `OS: ${device.os} ${device.version}`,
      `API Level: ${device.apiLevel || 'N/A'}`,
      `Type: ${device.type}`,
      `Status: ${device.status}`
    ],
    status: device.status,
    connected: device.connected
  });


  // Effects
  useEffect(() => {
    const wsStatusSubscription = service.subscribeToEvents('connection-status', (status) => {
      setConnectionStatus({
        isConnected: status.connected,
        lastUpdated: new Date()
      });
    });

    return () => wsStatusSubscription();
  }, [service]);

  useEffect(() => {
    fetchDevices();
  }, []);

  // Regular polling as backup
  useEffect(() => {
    const pollInterval = setInterval(() => {
      if (!isLoading) {
        fetchDevices();
      }
    }, 30000);

    return () => clearInterval(pollInterval);
  }, [isLoading]);


  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="grid grid-cols-12 gap-6">
        {/* Left Side - Upload, Device Selection when not fullscreen */}
        {!isFullscreen && (
           <div className="col-span-3 space-y-6"> 
            {/* APK Upload Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-medium mb-4">Upload Application</h2>
              {/* Your existing upload UI */}
             
              <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        className={`border-2 border-dashed rounded-lg p-6 text-center transition-colors ${
          dragActive
            ? 'border-indigo-500 bg-indigo-50'
            : uploadStatus.status === 'uploading'
            ? 'border-indigo-300 bg-indigo-50'
            : 'border-gray-300 hover:border-indigo-300'
        }`}
      >
  <input
    ref={fileInputRef}
    type="file"
    onChange={handleFileUpload}
    accept=".apk,.ipa"
    className="hidden"
  />
  <Package className="mx-auto h-12 w-12 text-gray-400 mb-4" />
  <div className="space-y-1">
    <button
      onClick={() => fileInputRef.current?.click()}
      className="text-indigo-600 hover:text-indigo-500"
    >
      Upload an application
    </button>
    <p className="text-sm text-gray-500">or drag and drop</p>
    <p className="text-xs text-gray-500">APK or IPA files up to 500MB</p>
  </div>

  {/* Upload Progress */}
  {uploadStatus.status === 'uploading' && (
    <div className="mt-4">
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-indigo-600 h-2.5 rounded-full transition-all"
          style={{ width: `${uploadStatus.progress}%` }}
        ></div>
      </div>
      <p className="text-sm text-gray-600 mt-2">
        Uploading... {uploadStatus.progress}%
      </p>
    </div>
  )}

  {/* Error Message */}
  {uploadStatus.status === 'error' && uploadStatus.message && (
    <div className="mt-4 text-sm text-red-600">
      {uploadStatus.message}
    </div>
  )}
</div>

              {/* App Info */}
              {appFile && (
                <div className="mt-4 bg-gray-50 rounded-lg p-4">
                  {/* Existing app info content */}
                </div>
              )}
            </div>

            {/* Device Selection Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium">Select Device</h2>
                <div className="flex space-x-2">
                  <select
                    className="text-sm border rounded-md px-2 py-1"
                    value={filters.os}
                    onChange={(e) => setFilters(prev => ({ ...prev, os: e.target.value }))}
                  >
                    <option value="all">All OS</option>
                    <option value="ios">iOS</option>
                    <option value="android">Android</option>
                  </select>
                  <select
                    className="text-sm border rounded-md px-2 py-1"
                    value={filters.status}
                    onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                  >
                    <option value="all">All Status</option>
                    <option value="available">Available</option>
                    <option value="in use">In Use</option>
                  </select>
                </div>
              </div>

              <div className="space-y-2 max-h-96 overflow-y-auto">
              {filteredDevices.map((device) => (
  <div
    key={device.id}
    onClick={() => setSelectedDevice(device)}
    className={`p-4 rounded-lg border cursor-pointer transition-colors ${
      selectedDevice?.id === device.id
        ? 'border-indigo-500 bg-indigo-50'
        : 'border-gray-200 hover:bg-gray-50'
    }`}
  >
    <div className="flex justify-between items-center">
      <div>
        <h3 className="font-medium text-gray-900">{device.name}</h3>
        <div className="flex items-center mt-1 text-sm text-gray-500">
          <span>{device.os}/ {device.version} / {device.deviceType} /</span>
          <span>{device.connected ? 'Connected' : 'Disconnected'}</span>
        </div>
        <span className={`px-2 py-1 text-xs font-medium rounded-full ${getStatusStyle(device.status)}`}>
        {device.status || 'Unknown'}
      </span>
      </div>
    </div>
  </div>
))}
              </div>
            </div>
          </div>
        )}

        {/* Device Stream Section */}
        <div className={`${isFullscreen ? 'col-span-12' : 'col-span-6'}`}>
          <div className="bg-white rounded-lg shadow">
            {/* Stream Header */}
            <div className="p-4 border-b flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <h2 className="text-lg font-medium">Device Stream</h2>
                {selectedDevice && (
                  <span className="text-sm text-gray-500">
                    {selectedDevice.name} • {selectedDevice.os} {selectedDevice.version}
                  </span>
                )}
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setStreamOrientation(prev => prev === 'portrait' ? 'landscape' : 'portrait')}
                  className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100"
                >
                  <RotateCw className="h-5 w-5" />
                </button>
                <button
                  onClick={handleStreamClick}
                  className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100"
                >
                  {isFullscreen ? (
                    <Minimize2 className="h-5 w-5" />
                  ) : (
                    <Maximize2 className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {/* Stream Container */}
            <div 
              ref={streamContainerRef}
              onClick={handleStreamClick}
              className={`relative cursor-pointer ${
                isFullscreen ? 'h-[calc(100vh-8rem)]' : 'aspect-[9/16]'
              } ${streamOrientation === 'landscape' ? 'aspect-video' : ''}`}
            >
              {selectedDevice ? (
                <img
                  src="/api/placeholder/400/720"
                  alt="Device Stream"
                  className="w-full h-full object-contain bg-gray-900"
                />
              ) : (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-900 text-gray-400">
                  <p>Select a device to start streaming</p>
                </div>
              )}
            </div>

            {/* Stream Controls */}
            <div className="p-4 border-t">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                    <Wifi 
                      className={`h-5 w-5 ${
                        connectionStatus.isConnected ? 'text-green-500' : 'text-red-500'
                      }`} 
                    />
                    <span className={`text-sm ${
                      connectionStatus.isConnected ? 'text-green-600' : 'text-red-600'
                    }`}>
                      {connectionStatus.isConnected ? 'Connected' : 'Disconnected'}
                      {connectionStatus.lastUpdated && 
                        ` (Last updated: ${connectionStatus.lastUpdated.toLocaleTimeString()})`
                      }
                    </span>
                  </div>
                  <button className="flex items-center space-x-1 text-sm text-gray-600 hover:text-gray-800">
                    <Camera className="h-4 w-4" />
                    <span>Screenshot</span>
                  </button>
                </div>
                <div className="flex space-x-2">
                  <button className="px-3 py-2 text-sm bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200">
                    Home
                  </button>
                  <button className="px-3 py-2 text-sm bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200">
                    Back
                  </button>
                  <button className="px-3 py-2 text-sm bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200">
                    Menu
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* Device Logs Section */}
            

       {/* Right Side - Logs */}
       <div className={`${
    isFullscreen ? 'col-span-3' : 'col-span-3'
  }`}>
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900">Device Logs</h2>
              <button
                onClick={() => setLogs([])}
                className="text-gray-400 hover:text-gray-500"
              >
                <RefreshCw className="h-5 w-5" />
              </button>
            </div>
            <div className="bg-gray-900 rounded-lg p-4 h-[calc(100vh-400px)] overflow-y-auto">
              <div className="space-y-2 font-mono text-sm">
                {logs.map((log, index) => (
                  <div
                    key={index}
                    className={`flex space-x-2 ${
                      log.level === 'error' ? 'text-red-400' :
                      log.level === 'warn' ? 'text-yellow-400' :
                      'text-green-400'
                    }`}
                  >
                    <span className="text-gray-500">
                      [{new Date(log.timestamp).toLocaleTimeString()}]
                    </span>
                    <span>{log.message}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Test Configuration Modal */}
<TestConfigModal
  isOpen={isConfigModalOpen}
  onClose={() => setIsConfigModalOpen(false)}
  testConfig={testConfig}
  setTestConfig={setTestConfig}
/>
      {/* Add your modal component here */}
    </div>
  );
};


export default DeviceTestingView;