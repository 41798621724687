import React, { useState, useEffect } from 'react';
import { useTestingPlatform } from '../../services/api-service';
import { CheckCircle2, XCircle, Clock, AlertCircle, RefreshCw, Filter } from 'lucide-react';

const TestProgressView = () => {
  const { service, useTestUpdates } = useTestingPlatform();
  const [testRuns, setTestRuns] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchTestRuns();
  }, []);

  const fetchTestRuns = async () => {
    setIsLoading(true);
    try {
      const tests = await service.getTestStatus();
      setTestRuns(tests);
    } catch (error) {
      console.error('Failed to fetch test runs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useTestUpdates((data) => {
    setTestRuns(current => {
      const exists = current.some(run => run.testId === data.testId);
      if (exists) {
        return current.map(run => 
          run.testId === data.testId ? { ...run, ...data } : run
        );
      }
      return [data, ...current];
    });

    if (selectedTest?.testId === data.testId) {
      setSelectedTest(prev => ({ ...prev, ...data }));
    }
  });

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 className="h-5 w-5 text-green-500" />;
      case 'failed':
        return <XCircle className="h-5 w-5 text-red-500" />;
      case 'running':
        return <Clock className="h-5 w-5 text-yellow-500" />;
      default:
        return <AlertCircle className="h-5 w-5 text-gray-500" />;
    }
  };

  const filteredTests = testRuns.filter(test => 
    filterStatus === 'all' ? true : test.status === filterStatus
  );

  const getErrorDetails = (error) => {
    if (typeof error === 'string') return error;
    return error.message || error.details || 'Unknown error occurred';
  };

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Test Runs List */}
          <div className="lg:col-span-1">
            <div className="bg-white shadow rounded-lg">
              <div className="p-4 border-b">
                <div className="flex items-center justify-between">
                  <h2 className="text-lg font-medium">Test Runs</h2>
                  <button 
                    onClick={fetchTestRuns}
                    className="text-gray-500 hover:text-gray-700"
                    disabled={isLoading}
                  >
                    <RefreshCw className={`h-5 w-5 ${isLoading ? 'animate-spin' : ''}`} />
                  </button>
                </div>
                <div className="mt-3 flex items-center space-x-2">
                  <Filter className="h-4 w-4 text-gray-400" />
                  <select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    className="text-sm border rounded-md px-2 py-1"
                  >
                    <option value="all">All Status</option>
                    <option value="running">Running</option>
                    <option value="completed">Completed</option>
                    <option value="failed">Failed</option>
                  </select>
                </div>
              </div>
              <div className="divide-y max-h-[calc(100vh-250px)] overflow-y-auto">
                {filteredTests.length === 0 ? (
                  <div className="p-4 text-center text-gray-500">
                    No test runs found
                  </div>
                ) : (
                  filteredTests.map((test) => (
                    <div
                      key={test.testId}
                      onClick={() => setSelectedTest(test)}
                      className={`p-4 cursor-pointer hover:bg-gray-50 ${
                        selectedTest?.testId === test.testId ? 'bg-indigo-50' : ''
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          {getStatusIcon(test.status)}
                          <div>
                            <p className="font-medium">Test #{test.testId}</p>
                            <p className="text-sm text-gray-500">
                              {test.deviceName} - {new Date(test.startTime).toLocaleString()}
                            </p>
                          </div>
                        </div>
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          test.status === 'completed' ? 'bg-green-100 text-green-800' :
                          test.status === 'failed' ? 'bg-red-100 text-red-800' :
                          'bg-yellow-100 text-yellow-800'
                        }`}>
                          {test.status}
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* Test Details */}
          <div className="lg:col-span-2">
            {selectedTest ? (
              <div className="bg-white shadow rounded-lg p-6">
                {/* ... [Previous test details code remains the same until error section] ... */}

                {/* Error Information */}
                {selectedTest.error && (
                  <div className="mt-6 bg-red-50 border border-red-200 rounded-lg p-4">
                    <div className="flex items-start space-x-3">
                      <AlertCircle className="h-6 w-6 text-red-600 mt-0.5" />
                      <div>
                        <h4 className="text-sm font-medium text-red-800">Error Details</h4>
                        <p className="mt-1 text-sm text-red-600">
                          {getErrorDetails(selectedTest.error)}
                        </p>
                        {selectedTest.errorStack && (
                          <details className="mt-2">
                            <summary className="text-sm text-red-600 cursor-pointer">
                              Stack Trace
                            </summary>
                            <pre className="mt-2 text-xs text-red-600 overflow-x-auto p-2 bg-red-50 rounded">
                              {selectedTest.errorStack}
                            </pre>
                          </details>
                        )}
                        {selectedTest.recommendations && (
                          <div className="mt-3 p-3 bg-white rounded border border-red-200">
                            <h5 className="text-sm font-medium text-red-800">Recommendations</h5>
                            <ul className="mt-1 text-sm text-red-600 list-disc list-inside">
                              {selectedTest.recommendations.map((rec, index) => (
                                <li key={index}>{rec}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Action Buttons */}
                <div className="mt-6 flex justify-end space-x-3">
                  {selectedTest.status === 'running' && (
                    <button
                      onClick={() => service.stopTest(selectedTest.testId)}
                      className="px-4 py-2 border border-red-300 text-red-700 rounded-md hover:bg-red-50"
                    >
                      Stop Test
                    </button>
                  )}
                  <button
                    onClick={() => setSelectedTest(null)}
                    className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                  >
                    Close Details
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full bg-white shadow rounded-lg p-6">
                <p className="text-gray-500">Select a test run to view details</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestProgressView;